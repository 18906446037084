export default Object.freeze([
    {
        type: "EVT_UNKNOWN",
        msg: "Unknown event",
        color: "red",
        icon: "fa-question-circle",
    },
    {
        type: "EVT_OPEN",
        msg: "Device opened",
        color: "green",
        icon: "fa-unlock",
    },
    {
        type: "EVT_COMBO_FAILED",
        msg: "Combination attempt failed",
        color: "red",
        icon: "fa-lock",
    },
    {
        type: "EVT_CODE_SAVED",
        msg: "Code saved",
        color: "green",
        icon: "fa-key",
    },
    {
        type: "EVT_CODE_UPDATED",
        msg: "Code updated",
        color: "green",
        icon: "fa-key",
    },
    {
        type: "EVT_TIME_UPDATED",
        msg: "Time updated",
        color: "green",
        icon: "fa-clock",
    },
    {
        type: "EVT_DISK_ERROR",
        msg: "Disk error",
        color: "red",
        icon: "fa-hdd",
    },
    {
        type: "EVT_BATT_ERROR",
        msg: "Battery is empty",
        color: "red",
        icon: "fa-battery-empty",
    },
    {
        type: "EVT_BATT_WARN",
        msg: "Battery is low",
        color: "yellow darken-3",
        icon: "fa-battery-quarter",
    },
]);