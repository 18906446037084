<template>
  <div style="height: 100%; overflow: hidden; display: flex; flex-direction: column">
    <Status></Status>
    <v-btn absolute dark fab right color="green darken-2" style="bottom: 3%" @click="refresh()">
      <v-icon>fa-redo</v-icon>
    </v-btn>
    <div style="flex-grow: 1; overflow: auto">
      <v-timeline dense>
        <v-timeline-item v-for="log in logs" fill-dot :key="log.id" :color="log.type.color" :icon="log.type.icon" small>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ log.type.msg }}</v-list-item-title>
              <v-list-item-subtitle>{{ log.ts }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-timeline-item>
      </v-timeline>
    </div>
  </div>
</template>

<script>
import Status from "@/components/Status.vue";
import EventTypes from "../model/EventTypes";
import Event from "../model/Event";
import CMD from "../lib/cmd";

// const tz = new Date().getTimezoneOffset() * 60 * 1000;
// const toTime = (ts) => {
//   let d = new Date(ts + tz);
//   return d.toLocaleString();
// };

export default {
  name: "Log",
  components: {
    Status
  },
  created() {
    this.deviceChanged();
    // this.gen();
  },
  mounted() {
    this.deviceChanged();
  },
  data() {
    return {
      logs: []
    };
  },
  methods: {
    async refresh() {
      await this.$store.connect();
      if (!this.$store.device.log_position) {
        this.$store.device.log_position = 0;
      }
      let cnt = 0;
      let len = this.logs.length;
      let pos = this.$store.device.log_position;

      this.$store.connection.write(CMD.CMD_EVENTS_LIST + pos++);

      let interval = setInterval(() => {
        if (this.logs.length == len) {
          if (cnt++ > 40) {
            console.log("canceling interval");
            this.$store.device.log_position = pos;
            clearInterval(interval);
          }
          return;
        }
        cnt = 0;
        len = this.logs.length;
        console.log("calling for another event");
        this.$store.connection.write(CMD.CMD_EVENTS_LIST + pos++);
      }, 100);
      console.log("refresh sent");
    },
    onData(data) {
      let entry = data.split(":");
      let evt = new Event();

      if (entry.length < 3 || entry[0] == 0) {
        console.log("data left", data);
        return;
      }

      evt.ts = new Date(entry[0] * 1000);
      evt.type = EventTypes[entry[1]];
      evt.id = entry[2];

      this.logs.push(evt);
      this.logs.sort((a, b) => {
        if (a.ts > b.ts) {
          return 1;
        } else if (a.ts < b.ts) {
          return -1;
        }
        return 0;
      });
    },
    async deviceChanged() {
      this.logs.splice(0, this.logs.length);
      this.$store.addListener(this.onData);
      await this.refresh();
      let events = await this.$store.db.events.get(this.$store.device.id);
      console.log("events", events);
      for (let event of events) {
        // data.ts = toTime(data.ts);
        event.type = EventTypes[event.type];
        this.logs.push(event);
      }
    },
    async gen() {
      for (let i = 0; i < EventTypes.length; i++) {
        this.$store.db.events.add({
          type: i,
          ts: new Date(),
          descpription: "event " + i,
          targetDeviceID: "26131726-cfae-47cc-9df8-42388077ceaa"
        });
      }
    }
  }
};
</script>